<template>
  <el-card class="box-card">
    <div
      slot="header"
      style="
        display: flex;
        justify-content: space-between;
        padding: 0 50px 0 10px;
      "
      class="clearfix"
    >
      <h3 class="inBlock">添加门店</h3>
      <back></back>
    </div>
    <div class="box">
      <div class="shopItem">
        <!-- <div class="label">基本信息</div> -->
        <div class="content">
          <el-form :model="from" ref="activateForm" label-width="100px">
          </el-form>
          <el-form
            :model="from"
            :rules="rules"
            ref="shopFrom"
            label-width="100px"
          >
            <el-form-item label="门店名称" prop="name">
              <el-input
                placeholder="请输入门店名称"
                v-model="from.name"
              ></el-input>
            </el-form-item>
            
            <el-form-item label="门店所属">
              <el-radio v-model="from.type" :label="1">实体门店</el-radio>
              <el-radio v-model="from.type" :label="2">虚拟门店</el-radio>
            </el-form-item>
            <el-form-item label="门店类型" prop="name">
              <el-select v-model="from.store_typeid" :placeholder="shopType">
                <el-option
                  v-for="item in shopTypelist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="门店描述">
              <el-input
                v-model="from.describe"
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
            <el-form-item class="time" label="营业时间">
              <el-input
                v-model="from.business_starttime"
                placeholder="开始营业时间"
              >
              </el-input>
              <span>至</span>
              <el-input
                v-model="from.business_endtime"
                placeholder="结束营业时间"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="营业状态">
              <el-radio v-model="from.status" :label="1">营业中</el-radio>
              <el-radio v-model="from.status" :label="2">休息中</el-radio>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                v-model="from.phone"
                placeholder="请输入联系方式"
              ></el-input>
            </el-form-item>
            <el-form-item label="门店位置">
              <div class="latitude">
                <div class="latitudeCtn">
                  <el-form
                    :model="from"
                    :rules="rules"
                    ref="shopFrom2"
                    label-width="100px"
                  >
                    <el-form-item label="省:">
                      <el-dropdown trigger="click">
                        <span
                          @click="getdiqu"
                          style="padding: 5px 20px"
                          class="shou"
                        >
                          {{ provice2 }}
                          <i class="el-icon-caret-bottom"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" trigger>
                          <el-dropdown-item
                            v-for="item in options"
                            :key="item.id"
                            @click.native="getprovice(item)"
                            >{{ item.name }}</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-form-item>
                    <el-form-item label="市:">
                      <el-dropdown
                        :disabled="options2.length < 1 ? true : false"
                        trigger="click"
                      >
                        <span style="padding: 5px 20px" class="shou">
                          {{ city2 }} <i class="el-icon-caret-bottom"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" trigger>
                          <el-dropdown-item
                            v-for="item in options2"
                            :key="item.id"
                            @click.native="getcity(item)"
                            >{{ item.name }}</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-form-item>
                    <el-form-item label="县/区:">
                      <el-dropdown
                        :disabled="options3.length < 1 ? true : false"
                        trigger="click"
                      >
                        <span style="padding: 5px 20px" class="shou">
                          {{ district2 }}
                          <i class="el-icon-caret-bottom"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" trigger>
                          <el-dropdown-item
                            v-for="item in options3"
                            :key="item.id"
                            @click.native="getdistrict(item)"
                            >{{ item.name }}</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-form-item>
                    <el-form-item label="详细地址">
                      <el-input
                        placeholder="请输入内容"
                        v-model="from.address"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="纬度">
                      <el-input
                        disabled
                        v-model="from.lat"
                        placeholder="请点击地图上的位置以获取纬度"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="经度">
                      <el-input
                        disabled
                        v-model="from.lng"
                        placeholder="请点击地图上的位置以获取经度"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
                <g-map @latitude="latitudeS"></g-map>
              </div>
            </el-form-item>
            <el-form-item label="营业执照">
              <div class="imgbox">
                <div class="imgItem" v-if="from.business_pic">
                  <span class="shou" @click="delBusiness_pic">×</span>
                  <img class="avatar" :src="imgurl + from.business_pic" alt="" />
                </div>
                <up-img v-else @addimg="upbusiness_pic" :type="'goods'"></up-img>
              </div>
            </el-form-item>

            <el-form-item label="门店火爆视频">
							<el-upload
                :disabled="this.$route.query.titletype == 1 ? true : false"
                :http-request="postImage"
                :thread="1"
                :on-remove="on_remove"
                action=""
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">最大只能上传5G</div>
              </el-upload>
              <div>
                <el-progress v-show="isProgressVis" :percentage="percentage" v-if="percentage" :color="customColor"></el-progress>
                <!-- el-progress :text-inside="true" :stroke-width="5" v-if="percentage" :percentage="percentage"
                            v-show="isProgressVis"></el-progress> -->
              </div>
              <video controls="controls" style="width:178px; height: 178px;" v-if="hot_video" :src="imgurl + hot_video"></video>
						</el-form-item>

            <el-form-item label="门店图片" style="margin-top: 70px">
              <el-alert
                title="注意! 最多可上传5张, 超过后会删除第一张图片!"
                type="warning"
              >
              </el-alert>
              <div class="imgbox">
                <div
                  v-for="(item, index) in imagesary"
                  :key="index"
                  class="imgItem"
                >
                  <span class="shou" @click="delImg(item)">×</span>
                  <img class="avatar" :src="imgurl + item" alt="" />
                </div>
                <up-img @addimg="upDataimg" :type="'goods'"></up-img>
              </div>
            </el-form-item>
          </el-form>
          <div class="submit">
            <span class="shou" @click="submit">确定</span>
            <span class="shou" @click="$router.go(-1)">返回</span>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import GMap from "./components/Gmap.vue";
import UpImg from "@/components/upImg.vue";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import {ACCEPT_CONFIG} from '../../../bus/config.js'
import OSS from 'ali-oss'
import {
  getarea,
  redactShop,
  getshopdetail,
  shopTypeListAll,
} from "@/api/api.js";
export default {
  components: { GMap, UpImg },
  name: "shopAdd",
  data() {
    return {
      from: {
        name: "",
        describe: "", // 描述
        business_starttime: "9: 00", // 营业时间
        business_endtime: "18:00", // 营业时间
        status: 1, // 营业状态
        phone: "", // 联系电话
        province: "", // 省
        city: "", // 市
        district: "", // 区
        address: "", // 地址
        lat: "", // 纬度
        lng: "", // 经度
        images: "", // 门店图片
        store_typeid: "", // 门店类型
        business_pic: "", // 营业执照
        type: 1, // 虚拟实体门店
      },
      shopTypelist: [],
      shopType: "请选择类型",
      imagesary: [],
      options: [],
      options2: [],
      options3: [],
      provice2: "选择省",
      city2: "选择市",
      district2: "选择区县",
      imgurl: "https://zkstore.qiannianjituan.com/",
      hot_video:"",   // 火爆视频
      percentage: 0,
      activate: false,
      rules: {
        name: [
          { required: true, message: "请填写门店名称", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请填写联系方式", trigger: "blur" }],
        lat: [
          {
            required: true,
            message: "请点击地图位置获取经纬度",
            trigger: "blur",
          },
        ],
        lng: [
          {
            required: true,
            message: "请点击地图位置获取经纬度",
            trigger: "blur",
          },
        ],
      },

    };
  },
  mounted() {
    getSystemsettings.then((res) => {
      console.log(res);
      this.imgurl = res;
    });
    // getSystemsettings().then((res=>{
    //  console.log(res)
    // }))
  },
  methods: {
    upbusiness_pic(img) {
      this.from.business_pic = img
    },
    delBusiness_pic() {
      this.from.business_pic = ''
    },
    delImg(item) {
      let index = this.imagesary.indexOf(item);
      this.imagesary.splice(index, 1);
    },
    upDataimg(e) {
      if (this.imagesary.length >= 5) {
        this.imagesary.shift();
      }
      this.imagesary.push(e);
    },

    // 省市区
    async getprovice(item) {
      this.provice2 = item.name;
      this.from.province = item.id;
      const { data } = await getarea({ area_id: item.id });
      this.options2 = data.data;
    },
    async getcity(item) {
      this.city2 = item.name;
      this.from.city = item.id;
      const { data } = await getarea({ area_id: item.id });
      this.options3 = data.data;
    },
    getdistrict(item) {
      this.district2 = item.name;
      this.from.district = item.id;
    },


    async postImage(option) {
      console.log('option====',option)
      var size = 5*1024*1024*1024
      console.log(option.file.type.split('/')[1])
      if(ACCEPT_CONFIG.video.indexOf(option.file.type.split('/')[1])==-1){
        this.$message.error('只能上传mp4,rmvb,mkv,wmv,flv');
        return false
      }
      if(option.file.size>size){
        this.$message.error('最大只能上传5G');
        return false
      }
      var time=new Date()
      var y=time.getFullYear()
      var m=time.getMonth()+1
      if(m<10){
        m=0+''+m
      }else{
        m=m
      }
      var d=time.getDate()
      var t=y+''+m+''+d
      console.log(time.getFullYear())
      var date=Date.parse(new Date())
        try {
          //构建上传文件参数
        this.fileLoading = true
        let self = this
        let client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: 'LTAI5tNiviqpbTa8qed4knVQ',
          accessKeySecret: 'Jui4XJUxmNN9f9iX68FjyUA6w7RSl4',
          bucket: 'storeqnkj',
        })
        let partSize={
          partSize:5*1024*1024
        }
          let objectName = '/addnews/'+t+'/'+escape(date)+'.' + option.file.name.split('.')[option.file.name.split('.').length-1]
          // 分片上传文件
          let result = await client.multipartUpload(objectName, option.file, partSize, {
            //进度条更新
          progress: async function(p) {
              self.percentage = parseInt(p * 100)
              self.isProgressVis = true
            }
          })
        // if(result.res.requestUrls[0])
        this.hot_video = result.res.requestUrls[0].split('?')[0].split('.com/')[1]
        self.isProgressVis = false

      } catch (error) {
        console.log(error)
        this.$message.error(error.message)
      }
    },

    on_remove(e){
      console.log(e)
      this.form.video_url=''
    },

    // 提交
    submit() {
      let from = this.from;
      this.imagesary.forEach((item) => {
        from.images = from.images + item.toString() + ",";
      });
      from.hot_video = this.hot_video;
      console.log(from.hot_video)
      from.images = from.images.substring(0, from.images.length - 1);
      console.log(from);
      this.$refs["shopFrom"].validate(async (valid) => {
        if (valid) {
          if (
            from.business_starttime == "" ||
            from.business_endtime == "" ||
            from.district == "" ||
            from.images == "" ||
            from.lat == "" ||
            from.lng == "" ||
            from.store_typeid == ""
          )
            return this.$message.warning("提交失败, 请检查内容是否填写正确!");
          const { data } = await redactShop(from);
          if (data.code != 200) return this.$message.error(data.data);
          this.$message.success(data.data);

          setTimeout(() => {
            this.$router.push("/shop/list");
          }, 500);
        } else {
          this.$message.warning("提交失败, 请检查内容是否填写正确!!!");
          return false;
        }
      });
    },

    setStatus1() {
      this.from.status = "1";
    },
    setStatus2() {
      this.from.status = "2";
    },
    latitudeS(e) {
      this.from.lat = e[1];
      this.from.lng = e[0];
    },
    async getdiqu(id) {
      if (id) {
      } else {
        const { data } = await getarea();
        this.options = data.data;
      }
    },
    async shopDetail(id) {
      const { data } = await getshopdetail(id);
      if (data.code != 200) return false;
      console.log(data);
      let from = data.data;
      this.from.id = id.store_id;
      this.from.name = from.name;
      this.from.describe = from.describe;
      this.from.business_starttime = from.business_starttime;
      this.from.business_endtime = from.business_endtime;
      this.from.status = from.status;
      this.from.store_typeid = from.store_typeid;
      this.from.phone = from.phone;
      this.from.province = from.province;
      this.from.city = from.city;
      this.from.district = from.district;
      this.from.address = from.address;
      this.from.lat = from.lat;
      this.from.lng = from.lng;
      this.from.lat = from.lat;
      this.from.business_pic = from.business_pic;
      this.from.type = from.type;
      this.provice2 = from.province_name;
      this.city2 = from.city_name;
      this.district2 = from.district_name;
      this.hot_video = from.hot_video
      let img = from.images;
      if (img != "") {
        img = img.split(",");
        this.imagesary = img;
      }
    },
    async getTypelist() {
      const { data } = await shopTypeListAll();
      if (data.code != 200) return this.$message.error(data.data);
      this.shopTypelist = data.data;
      console.log(this.shopTypelist);
    },
  },
  created() {
    this.getdiqu();
    this.getTypelist();
    getSystemsettings.then((res) => {
      console.log('123==',res);
      this.imgurl = res;
    });
    if (this.$route.query.id) {
      this.shopDetail({ store_id: this.$route.query.id });
    }
    if (this.$route.query.flag) {
      this.activate = this.$route.query.flag;
      console.log("flag", this.activate);
    }
  },
};
</script>

<style scoped lang='less'>
.box {
  .shopItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .label {
      flex: 1;
      border: 1px solid #eee;
      text-align: center;
      padding-top: 40px;
      font-weight: 900;
      font-size: 16px;
      margin-right: 10px;
    }
    .content {
      flex: 8;
      border: 1px solid #eeeeee;
      padding: 20px;
      .el-form {
        .el-form-item {
          .el-dropdown {
            span {
              border: 1px solid #c0c4cc !important;
              color: #777;
            }
          }
          .imgbox {
            display: flex;
            .imgItem {
              width: 178px;
              height: 178px;
              padding: 10px;
              margin-left: 10px;
              border-radius: 5px;
              position: relative;
              border: 1px solid #eee;
              span {
                position: absolute;
                background-color: #ffd1d1;
                top: 0;
                right: 0;
                height: 20px;
                text-align: center;
                line-height: 20px;
                width: 20px;
                z-index: 100;
                color: aliceblue;
                &:hover {
                  background-color: #ff4544;
                }
              }
            }
            .avatar {
              width: 100%;
              height: 100%;
            }
          }
          .el-dropdown {
            span {
              padding: 5px 10px;
              border: 1px solid #333;
            }
          }
          .el-input {
            width: 375px;
          }
          .el-textarea {
            width: 500px;
          }
          .latitude {
            display: flex;
            align-content: center;
            .latitudeCtn {
              padding: 100px 10px 0 0px;
              .el-form-item {
                margin-bottom: 10px;
                position: relative;
                left: -80px;
              }
            }
          }
        }
      }
    }
  }
  .submit {
    padding-top: 20px;
    border-top: 1px solid #eee;
    margin-bottom: 50px;
    span {
      background-color: #19c2ff;
      padding: 5px 10px;
      margin-right: 10px;
      color: #fff;
      &:last-child {
        background-color: #1bc6a3;
      }
    }
  }
}
.el-alert {
  margin: 20px 0 15px 0;
}
/deep/.el-range-separator {
  width: 10%;
}
/deep/.el-date-editor {
  margin-right: 20px;
}
.el-popper {
  height: 260px;
  overflow: auto;
}
/deep/.time {
  .el-input {
    margin: 0 10px 0 10px;
  }
}
</style>