<template>
    <div class="amap-page-container">
      <el-amap-search-box
        class="search-box"
        :search-option="searchOption" 
        :on-search-result="onSearchResult"
      ></el-amap-search-box>
      <el-amap
        ref="map"
        vid="amapDemo"
        :amap-manager="amapManager"
        :center="center"
        :zoom="zoom"
        :plugin="plugin"
        :events="events"
        class="amap-demo"
      >
        <el-amap-marker :position="center"></el-amap-marker>
      </el-amap>
    </div>
</template>
<script>
//引入 获取实例
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
let Geocoder; //先声明变量，
export default {
  data() {
    let self = this;
    return {
      amapManager,
      zoom: 12,
      input: "",
      markers: [],
      searchOption: { // 搜索条件
    
      },
      center: [113.771375, 34.768696],
      events: {
        init: (o) => {
          o.getCity((result) => {
            // console.log(result);
          });
        },
        moveend: () => {},
        zoomchange: () => {},
        click: (e) => {
          self.center = [e.lnglat.lng, e.lnglat.lat];
          // console.log(self.input);
          console.log(e.lnglat.lng);
          console.log(e.lnglat.lat);
          self.$emit("latitude", self.center);
          Geocoder.getAddress(self.center, function (status, result) {
            //根据坐标获取位置
            if (status === "complete" && result.info === "OK") {
              self.input = result.regeocode.formattedAddress;
              document.querySelector(".search-box-wrapper input").value =
                self.input;
            }
          });
        },
      },
      plugin: [
        {
          pName: "Geocoder", //使用AMap.Geocoder插件
          events: {
            init(o) {
              Geocoder = o; // o 则是AMap.Geocoder的实例 对外部的Geocoder变量进行赋值，在任何地方就都可以使用了
              //self.center 具体坐标 （数组格式） ，function 回调函数
              o.getAddress(self.center, function (status, result) {
                if (status === "complete" && result.info === "OK") {
                  //result.regeocode.formattedAddress就是具体位置
                  self.input = result.regeocode.formattedAddress;
                  // console.log(result);
                  //对搜索组件的input进行赋值
                  document.querySelector(".search-box-wrapper input").value =
                    self.input;
                }
              });
            },
          },
        },
        {
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          pName: "Geolocation", // AMap-Geolocation 定位插件
          events: {
            init(o) {
              //getCurrentPosition 获取当前位置的方法
              //注意 虽然进页面就会调用这个方法，但是data()中center要有默认值，不然会报错
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  let lng = result.position.lng;
                  let lat = result.position.lat;
                  self.center = [lng, lat];
                  self.loaded = true;
                  self.zoom = 14;
                  self.$nextTick();
                }
              });
            },
          },
        },
      ],
    };
  },
  methods: {
    //点击搜索后触发的事件
    onSearchResult([ {lng, lat} ]) {
      // console.log(pois);
      // this.input = document.querySelector(".search-box-wrapper input").value;
      // //这边类似模糊查询 会返回一个数组，我就直接取第一个值了。
      // this.center = [pois[0].lng, pois[0].lat];
      this.center = [lng, lat];
      this.$emit("latitude", this.center);
    },
  },
};
</script>
<style scoped lang='less'>
.amap-page-container {
  width: 900px;
  height: 400px;
  margin-bottom: 20px;
}
/deep/.search-box-wrapper {
  border: 1px solid #339be0;
  margin-bottom: 20px;
  height: 42px !important;
}
/deep/.search-btn {
  background-color: #339be0 !important;
  width: 100px !important;
  color: #fff;
}
/deep/.el-vue-search-box-container  {
  width: 800px !important;
}
</style>
